import React, { Component } from "react";
import "./App.css";
import ErrorBoundary from "./hoc/errorBoundary";
import { Route } from "react-router-dom";
import { HOME, DASHBOARD, LANDING, LOGIN_PATH } from "./config/path";
import  Header  from "./common/sysco-ui-components/components/Header";
import  TabView  from "./common/sysco-ui-components/components/tabs";
import Search from './containers/searchContainer';
import Change_Password from "./containers/changePassContainer";
import User_Guide from "./components/userGuide/userGuide";
import Login from "./components/login";
import VendorLogin from "./components/loginVendor";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingIndicator from "./common/sysco-ui-components/components/LoadingIndiactor/LoadingIndicator";
import Dashboard from "./components/Dashboard/Dashboard";
import RedirectLogin from "./components/loginVendor/redirectLogin";

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      headerItems: [
        { title: "Dashboard", eventKey: Dashboard, icon: "appstore" },
        { title: "Search", eventKey: Search, icon: "appstore" },
        { title: "User Guide", eventKey: User_Guide, icon: "appstore" },
        { title: "Change Password", eventKey: Change_Password, icon: "appstore" }
  
      ]
    };
  }
  componentDidMount(){
  }
  render() {
    
    return (
      <div className="App">
       <Header headerItems={this.state.headerItems} title="SUPPLIER PAYABLES" />
         <TabView />
        <ErrorBoundary>
        <LoadingIndicator />
          <div className="loadingIndicatorMain" id="loadingIndicatorMain" />
          <Route exact path={HOME} component={Login} />
          <Route exact path={LOGIN_PATH} component={RedirectLogin} />
          <Route exact path={LANDING} component={VendorLogin} />
          <Route exact path={DASHBOARD} component={Dashboard} />
        </ErrorBoundary>
        <ToastContainer
          autoClose={2500}
          position={toast.POSITION.TOP_CENTER}
          transition={Flip}
        />
      </div>
    );
  }
}

export default App;
